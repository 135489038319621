import { useFlag } from '@unleash/proxy-client-react';
import React from 'react';
import { shallowEqual } from 'react-redux';

import { FeatureTogglesNames } from '../../../main/env/feature-toggle-names';
import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { Icon, PermissionsGuard, UserNavProfile } from '../../../shared';
import Permissions from '../../../shared/constants/Permissions';
import { intercomAPI } from '../../../shared/services/intercom';
import { FlowTypes, resetSelectedCampaign } from '../../auth/store';
import { hasRole, isAdmin, isAdminRole } from '../../auth/utils';
import { PLATFORM_ADMIN_ROUTES } from '../../platform-admin/routes';
import { setShowIntercom } from '../store/dashboardSlice';

import { DashboardNavItem } from './DashboardNavItem';
import {
  Tab,
  analyticsTabs,
  analyticsZaalTabs,
  financesTabs,
  getWebhooksTabs,
  loanCreatorTabs,
  teamSettingsTabs,
} from './nestedLinks';

interface DashboardNavProps {
  handleNavBar: () => void;
  openNavBar: boolean;
  toolkitMenuOpen: boolean;
}

const DashboardNav: React.FC<DashboardNavProps> = ({
  handleNavBar,
  openNavBar,
  toolkitMenuOpen,
}) => {
  const dispatch = useAppDispatch();
  const { showIntercom } = useAppSelector((state) => state.dashboard);

  const PARTNER_DBP_WEBHOOKS = useFlag(FeatureTogglesNames.PARTNER_DBP_WEBHOOKS);
  const PARTNER_P2P_WEBHOOKS = useFlag(FeatureTogglesNames.PARTNER_P2P_WEBHOOKS);
  const PARTNER_DBP_ACCOUNT_VALIDATION_UPLOAD = useFlag(
    FeatureTogglesNames.PARTNER_DBP_ACCOUNT_VALIDATION_UPLOAD,
  );
  const PARTNER_P2P_ACCOUNT_VALIDATION_UPLOAD = useFlag(
    FeatureTogglesNames.PARTNER_P2P_ACCOUNT_VALIDATION_UPLOAD,
  );
  const PARTNER_DBP_DEVELOPER_TOOLKIT = useFlag(FeatureTogglesNames.PARTNER_DBP_DEVELOPER_TOOLKIT);
  const PARTNER_P2P_DEVELOPER_TOOLKIT = useFlag(FeatureTogglesNames.PARTNER_P2P_DEVELOPER_TOOLKIT);

  const TOGGLES_LOAN_CREATOR_ANALYTICS_TAB = useFlag(
    FeatureTogglesNames.TOGGLES_LOAN_CREATOR_ANALYTICS_TAB,
  );

  const { authToken: authInfo } = useAppSelector((state) => state.auth, shallowEqual);
  const { finishedSetup, checklistFinished, partner } = useAppSelector(
    (state) => state.auth,
    shallowEqual,
  );

  if (!partner) {
    return null;
  }
  const { name, icons, flowType } = partner.details;

  const canViewLoanCreator =
    partner.details.flowType === FlowTypes.LOAN_CREATOR &&
    authInfo &&
    (hasRole(authInfo, Permissions.MANAGE_PARTNER_LOANS) || isAdmin(authInfo)) &&
    !isAdminRole(authInfo);

  const canViewFinances =
    authInfo &&
    (hasRole(authInfo, Permissions.FINANCES_MANAGE) ||
      hasRole(authInfo, Permissions.FINANCES_VIEW) ||
      isAdmin(authInfo)) &&
    !isAdminRole(authInfo);

  const canViewTeamSettings =
    authInfo &&
    (hasRole(authInfo, Permissions.BILL_PAYMENTS_VIEW) ||
      hasRole(authInfo, Permissions.MANAGE_PARTNER_LOANS) ||
      isAdmin(authInfo)) &&
    !isAdminRole(authInfo);

  const canViewAnalytics = canViewTeamSettings && flowType !== FlowTypes.LOAN_CREATOR;

  const canViewZaalAnalytics = flowType === FlowTypes.LOAN_CREATOR && true && canViewTeamSettings;

  const isAdminUser =
    authInfo &&
    (hasRole(authInfo, Permissions.PLATFORM_ADMIN_EDIT) ||
      hasRole(authInfo, Permissions.PLATFORM_ADMIN_VIEW));

  const clickSupport = () => {
    intercomAPI(showIntercom ? 'hide' : 'show');
    dispatch(setShowIntercom(!showIntercom));
  };

  const filteredTabs = (tabs: Tab[]): Tab[] =>
    // @ts-ignore
    tabs.filter((tab) => tab?.flowType?.includes(partner?.details.flowType));
  const NOTP2P = partner?.details.flowType !== FlowTypes.AFFILIATE_PARTNER;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let webhooksTabsFiltered: any[] = [];

  if (NOTP2P && PARTNER_DBP_DEVELOPER_TOOLKIT) {
    webhooksTabsFiltered = getWebhooksTabs(
      PARTNER_DBP_ACCOUNT_VALIDATION_UPLOAD,
      PARTNER_DBP_WEBHOOKS,
    );
  }

  if (!NOTP2P && PARTNER_P2P_DEVELOPER_TOOLKIT) {
    webhooksTabsFiltered = getWebhooksTabs(
      PARTNER_P2P_ACCOUNT_VALIDATION_UPLOAD,
      PARTNER_P2P_WEBHOOKS,
    );
  }

  return (
    <div className={openNavBar ? 'dashboard-nav' : 'dashboard-nav dashboard-nav--close'}>
      <button
        type="button"
        className={
          openNavBar ? 'dashboard-nav__arrow' : 'dashboard-nav__arrow dashboard-nav__arrow--close'
        }
        onClick={handleNavBar}
      >
        <Icon name="left-arrow" classes="dashboard-nav__arrowIcon" />
      </button>
      <div className="dashboard-nav__profileWrapper">
        <UserNavProfile
          name={name}
          text="Zirtue Pay Subscripition"
          small
          icons={icons?.highRes || '/images/dashboard/avatar-new.svg'}
        />
      </div>
      <div className="dashboard-nav__menu">
        <ul className="dashboard-nav__page-list">
          <>
            {!isAdminUser &&
              !checklistFinished &&
              partner &&
              partner.details.flowType !== FlowTypes.LOAN_CREATOR && (
                <DashboardNavItem
                  linkTo="/init-setup"
                  title="Get started"
                  iconName="binoculars"
                  id="onboarding-step-0"
                  nestedLinks={[]}
                />
              )}
            {canViewFinances &&
              canViewLoanCreator &&
              finishedSetup &&
              loanCreatorTabs.map(({ children, iconName, id, linkTo, title }) => (
                <DashboardNavItem
                  key={id}
                  linkTo={linkTo}
                  title={title}
                  iconName={iconName}
                  id={id}
                  nestedLinks={children}
                />
              ))}
            {canViewAnalytics &&
              finishedSetup &&
              analyticsTabs.map(({ children, iconName, id, linkTo, title }) => (
                <DashboardNavItem
                  key={id}
                  linkTo={linkTo}
                  title={title}
                  iconName={iconName}
                  id={id}
                  nestedLinks={children}
                />
              ))}
            {canViewZaalAnalytics &&
              finishedSetup &&
              TOGGLES_LOAN_CREATOR_ANALYTICS_TAB &&
              analyticsZaalTabs.map(({ children, iconName, id, linkTo, title }) => (
                <DashboardNavItem
                  key={id}
                  linkTo={linkTo}
                  title={title}
                  iconName={iconName}
                  id={id}
                  nestedLinks={children}
                />
              ))}
            {canViewFinances &&
              finishedSetup &&
              filteredTabs(financesTabs).map(({ children, iconName, id, linkTo, title }) => (
                <DashboardNavItem
                  key={id}
                  linkTo={linkTo}
                  title={title}
                  iconName={iconName}
                  id={id}
                  nestedLinks={children}
                  onClick={() => dispatch(resetSelectedCampaign())}
                />
              ))}
            {partner?.details.flowType !== FlowTypes.LOAN_CREATOR &&
              webhooksTabsFiltered.map(({ children, iconName, id, linkTo, title }) => (
                <DashboardNavItem
                  key={id}
                  linkTo={linkTo}
                  title={title}
                  iconName={iconName}
                  id={id}
                  nestedLinks={children}
                  toolkitMenuOpen={toolkitMenuOpen}
                />
              ))}
            {canViewTeamSettings &&
              finishedSetup &&
              teamSettingsTabs.map(({ children, iconName, id, linkTo, title }) => (
                <DashboardNavItem
                  key={id}
                  linkTo={linkTo}
                  title={title}
                  iconName={iconName}
                  id={id}
                  nestedLinks={children}
                />
              ))}
            <PermissionsGuard
              permissions={{
                $or: [Permissions.PLATFORM_ADMIN_VIEW, Permissions.PLATFORM_ADMIN_EDIT],
              }}
              bypassForAdmin={false}
            >
              <DashboardNavItem
                linkTo={PLATFORM_ADMIN_ROUTES.USERS}
                title="Admin Users"
                iconName="userNav"
                nestedLinks={[]}
              />
              {finishedSetup &&
                partner.details.flowType === FlowTypes.LOAN_CREATOR &&
                loanCreatorTabs.map(({ children, iconName, id, linkTo, title }, ind) => {
                  if (ind === 1)
                    return (
                      <DashboardNavItem
                        key={id}
                        linkTo={linkTo}
                        title={title}
                        iconName={iconName}
                        id={id}
                        nestedLinks={children}
                      />
                    );
                  return null;
                })}

              <DashboardNavItem
                linkTo={PLATFORM_ADMIN_ROUTES.LOANS}
                title="Admin Loans"
                iconName="invoice-dollar"
                nestedLinks={[]}
              />
              <DashboardNavItem
                linkTo={PLATFORM_ADMIN_ROUTES.TRANSACTIONS}
                title="Admin Transactions"
                iconName="bill-dollar"
                nestedLinks={[]}
              />
              <DashboardNavItem
                linkTo={PLATFORM_ADMIN_ROUTES.PARTNERS}
                title="Admin Partners"
                iconName="building"
                nestedLinks={[]}
              />
              <DashboardNavItem
                linkTo={PLATFORM_ADMIN_ROUTES.PAYMENTS}
                title="Admin Bill Payments"
                iconName="credit-card-nav"
                nestedLinks={[]}
              />
              {teamSettingsTabs.map(({ children, iconName, id, linkTo, title }) => (
                <DashboardNavItem
                  key={id}
                  linkTo={linkTo}
                  title={title}
                  iconName={iconName}
                  id={id}
                  nestedLinks={children}
                />
              ))}
            </PermissionsGuard>
          </>
        </ul>

        <div className="dashboard-nav__page dashboard-nav__page--support">
          <a
            className="dashboard-nav__page-link"
            style={{ backgroundColor: 'transparent', border: 0 }}
            href="https://docs.zirtue.com/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="dashboard-nav__page-content">
              <div className="dashboard-nav__page-icon-box">
                <Icon
                  name={
                    partner?.details.flowType === FlowTypes.LOAN_CREATOR
                      ? 'book-open-solid-dotted'
                      : 'question-circle'
                  }
                  classes="dashboard-nav__page-icon"
                />
              </div>
              <p className="dashboard-nav__page-title">
                {partner?.details.flowType === FlowTypes.LOAN_CREATOR ? 'Docs' : 'Support'}
              </p>
            </div>
          </a>
        </div>
      </div>
      <div className="dashboard-nav__page dashboard-nav__page--help">
        <button
          type="button"
          className="dashboard-nav__page-link"
          onClick={() => clickSupport()}
          style={{ backgroundColor: 'transparent', border: 0 }}
        >
          <div className="dashboard-nav__page-icon-box">
            <Icon name="comments" classes="dashboard-nav__page-icon" />
          </div>
          <p className="dashboard-nav__page-title">Get help</p>
        </button>
      </div>
    </div>
  );
};

export default DashboardNav;
